<template>
    <div class="page">
        <CompTable ref="comp_table" title="岗位管理" :columns="columns" table-api="/gateway/org/pc/grassrootsPost/selectPostInfoPage">
            <template #operate>
                <Button icon="ios-add" type="primary" @click="onDisplayAddForm()">新增</Button>
            </template>
        </CompTable>

        <CompModal ref="form_modal" title="岗位编辑" height="auto">
            <div style="padding: 20px 30px 20px 20px">
                <ElemFormItem title="岗位名称" name="postName">
                    <Input style="width:350px" v-model="formdata.postName" :maxlength="50" placeholder="请填写岗位名称"></Input>
                </ElemFormItem>
            </div>
            <template #operate="evt">
                <div class="operate-btns">
                    <div class="operate-btn highlight" @click="onSubmit">保存</div>
                    <div class="operate-btn" @click="evt.close">取消</div>
                </div>
            </template>
        </CompModal>
    </div>
</template>

<script>
import CompTable from "@/views/jointly/components/comp-table.vue"
import ElemFormItem from "@/views/jointly/components/elem-form-item.vue"
import CompModal from "@/views/residentdatabase/components/CompModal.vue"

export default {
    components: { CompTable, CompModal, ElemFormItem },

    data() {
        return {
            // 表单数据
            formdata: {},
            // 表格配置
            columns: [
                {
                    title: "岗位名称",
                    key: "postName",
                    minWidth: 200,
                    align: "center",
                },
                {
                    title: "人员数量",
                    key: "adminCount",
                    minWidth: 150,
                    align: "center",
                },
                {
                    title: "操作",
                    align: "center",
                    fixed: "right",
                    minWidth: 200,
                    render: (h, params) => {
                        return [
                            h(
                                "Button",
                                {
                                    props: {
                                        type: "info",
                                        size: "small",
                                    },
                                    style: "margin:0 3px",
                                    nativeOn: {
                                        click: () => {
                                            this.onDisplayAddForm(params.row)
                                        },
                                    },
                                },
                                "修改"
                            ),
                            h(
                                "Button",
                                {
                                    props: {
                                        type: "error",
                                        size: "small",
                                    },
                                    style: "margin:0 3px",
                                    nativeOn: {
                                        click: () => {
                                            this.$Modal.confirm({
                                                title: "删除确定",
                                                content: "您正在删除数据，是否继续？",
                                                onOk: () => {
                                                    this.$post("/gateway/org/pc/grassrootsPost/deletePost", { postId: params.row.postId }).then(res => {
                                                        if (res.code == 200) {
                                                            this.$Message.success({
                                                                content: "删除成功",
                                                                background: true,
                                                            })
                                                            // 刷新表格数据
                                                            this.$refs.comp_table.onRefresh()
                                                        } else {
                                                            this.$Message.error({
                                                                content: res.desc || "删除失败",
                                                                background: true,
                                                            })
                                                        }
                                                    })
                                                },
                                            })
                                        },
                                    },
                                },
                                "删除"
                            ),
                        ]
                    },
                },
            ],
        }
    },

    methods: {
        onDisplayAddForm(data = {}) {
            this.formdata = JSON.parse(JSON.stringify(data))
            this.$refs.form_modal.display()
        },

        /**
         * 添加事件
         */
        onSubmit() {
            const formdata = this.formdata

            this.$post("/gateway/org/pc/grassrootsPost/" + (formdata.postId ? "updatePost" : "addPost"), formdata, {
                "Content-Type": "application/json",
            }).then(res => {
                if (res.code == 200) {
                    this.$Message.success((formdata.postId ? "修改" : "新增") + "成功")
                    this.$refs.comp_table.onRefresh()
                    this.$refs.form_modal.close()
                } else this.$Message.error(res.desc)
            })
        },
    },
}
</script>
<style lang="less" scoped>
.structure-box {
    .item-box {
        margin-bottom: 10px;
        display: flex;
        align-items: center;

        &:last-child {
            margin-bottom: 0;
        }

        .value {
            padding: 6px 8px;
            border: 1px solid #e3e3e3;
            border-radius: 4px;
            width: 280px;
        }

        .btn {
            cursor: pointer;
            padding: 6px 15px;
            background: #e60000;
            color: #fff;
            border-radius: 4px;
            margin-left: 10px;
        }
    }
}
</style>
